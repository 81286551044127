import React, { useMemo, useState } from "react";
import { UserPersonaTask } from "@noa/types";
import PersonasSlideOutPanel from "~/components/Tasks/Personas/PersonasSlideOutPanel/PersonasSlideOutPanel";
import { useTasks } from "~/context/TasksContext";
import NoaHeader from "~/components/NoaHeader/NoaHeader";
import PersonasDescription from "~/components/Tasks/Personas/PersonasDescription/PersonasDescription";
import PersonasCard from "~/components/Tasks/Personas/PersonasCard/PersonasCard";

const PersonasPage = () => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState<
    UserPersonaTask | undefined
  >(undefined);
  const { tasksByCategory, loading } = useTasks();

  const personaTasks = useMemo(
    () =>
      tasksByCategory["user-persona"]?.sort(
        (p1: UserPersonaTask, p2: UserPersonaTask) => p1.age - p2.age,
      ),
    [tasksByCategory],
  );

  const handleSlideOutPanel = (persona: UserPersonaTask) => {
    setIsSliderOpen(!isSliderOpen);
    setSelectedPersona(persona);
  };

  if (loading) return;

  return (
    <section className="flex-1 flex flex-col relative">
      <NoaHeader />

      <PersonasDescription />

      <div className="pt-24 flex w-full flex-col items-center">
        <span className="text-xs font-semibold text-content-secondary pb-6">
          SELECT A PERSONA BELOW
        </span>

        <div className="flex flex-row gap-4" data-testid="persona-cards">
          {personaTasks.map((it) => (
            <PersonasCard
              key={it.id}
              persona={it}
              handleSlideOutPanel={handleSlideOutPanel}
            />
          ))}

          {selectedPersona && (
            <PersonasSlideOutPanel
              isOpen={isSliderOpen}
              setIsOpen={setIsSliderOpen}
              selectedPersona={selectedPersona}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default PersonasPage;
