import { X as XIcon } from "@phosphor-icons/react";
import { useUser } from "~/context/UserContext";

const TaskTipBoxTest = ({ taskId }: { taskId: string }) => {
  const { saveTaskTipBoxChoice } = useUser();

  const onClick = async () => {
    await saveTaskTipBoxChoice(taskId);
  };

  return (
    <div
      className="bg-background-default p-6 relative w-full flex col-span-full mt-4 rounded-md dark:bg-gray-700"
      data-testid="task-tipbox"
    >
      <div className="pr-8">
        <span className="font-bold"> 💡 Tip: </span>
        <span>
          Try interacting with me like a real person! You can ask about my
          reading preferences, what I usually read on holiday, which social
          media platforms I’m on, or even test marketing copy with me.
          There&apos;s so much we can chat about!
        </span>
      </div>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        onClick={onClick}
        className="absolute right-4 top-4 cursor-pointer"
      >
        <XIcon height="16" width="16" />
      </button>
    </div>
  );
};

export default TaskTipBoxTest;
