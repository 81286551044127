import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import React from "react";

import LoginPage from "~/routes/Login/LoginPage";
import LogoutPage from "~/routes/Login/Logout";
import { SupportPage } from "~/routes/Support/SupportPage";
import MainLayout from "~/routes/Layout/MainLayout";
import ChatIntroPage from "~/routes/Chat/ChatIntroPage";
import { ChatProvider } from "~/context/ChatContext";
import ChatPage from "~/routes/Chat/ChatPage";
import PersonasPage from "~/routes/Tasks/Personas/PersonasPage";
import PersonaPage from "~/routes/Tasks/Persona/PersonaPage";
import AdminLayout from "~/routes/Admin/AdminLayout";
import { AdminOrganisationsPage } from "~/routes/Admin/AdminOrganisationsPage";
import { AdminOrganisationProvider } from "~/context/AdminOrganisationContext";
import { AdminOrganisationDetailPage } from "~/routes/Admin/AdminOrganisationDetailPage";
import AuthLayout from "~/routes/Layout/AuthLayout";
import AuthAdminLayout from "~/routes/Layout/AuthAdminLayout";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/support" element={<SupportPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/logout" element={<LogoutPage />} />

      <Route element={<AuthLayout />}>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<ChatIntroPage />} />

          <Route path="chat" element={<Outlet />}>
            <Route index element={<ChatIntroPage />} />
            <Route
              path=":id"
              element={
                <ChatProvider>
                  <ChatPage />
                </ChatProvider>
              }
            />
          </Route>

          <Route path="tasks" element={<Outlet />}>
            <Route index element={<Navigate to="/" />} />
            <Route path="personas" element={<PersonasPage />} />
            <Route path="personas/:taskId/chat" element={<PersonaPage />} />
          </Route>
        </Route>

        <Route element={<AuthAdminLayout />}>
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<Navigate to="organisations" replace />} />

            <Route path="organisations">
              <Route index element={<AdminOrganisationsPage />} />
              <Route
                path=":id"
                element={
                  <AdminOrganisationProvider>
                    <AdminOrganisationDetailPage />
                  </AdminOrganisationProvider>
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
