import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "~/context/AuthContext";
import { useAnalytics } from "~/context/AnalyticsProvider";

function LogoutPage() {
  const { signOut } = useAuth();
  const { log } = useAnalytics();

  useEffect(() => {
    signOut().then();

    log({ type: "logout_success" });
  }, [log, signOut]);

  return <Navigate to="/" replace />;
}

export default LogoutPage;
