import { ChatMessage as IChatMessage, ChatStatus } from "@noa/types";
import React, { useMemo } from "react";
import { Loader } from "ui";
import { Timestamp } from "firebase/firestore";
import { useChat } from "~/context/ChatContext";
import { ChatMessage } from "./ChatMessage";
import PersonasIntroPanel from "~/components/Tasks/Personas/PersonasIntroPanel/PersonasIntroPanel";
import { useTasks } from "~/context/TasksContext";

interface ChatMessagesProps {
  messages: IChatMessage[];

  scrollToBottom(): void;
}

const NOA_AVATAR_ICON_URL = "/noa.svg";

const isMessageFromToday = (timestamp?: Timestamp): boolean => {
  if (!timestamp) {
    return false;
  }
  const messageDate = timestamp.toDate().toLocaleDateString();
  const todaysDate = new Date().toLocaleDateString();
  return todaysDate === messageDate;
};

export function ChatMessages({ messages, scrollToBottom }: ChatMessagesProps) {
  const { rateMessage, regenerateMessage, isReady, chat } = useChat();
  const { taskId, status } = chat;
  const { getTaskById, getTaskAvatar } = useTasks();
  const aiAvatarUrl = getTaskAvatar(taskId) || NOA_AVATAR_ICON_URL;

  const task = useMemo(
    () => (taskId ? getTaskById(taskId) : null),
    [getTaskById, taskId],
  );

  const aiName = task ? task.name : "NOA";

  const shouldDisplayTodayDivider = useMemo(() => {
    return messages.length > 0 && isMessageFromToday(messages[0].updatedAt);
  }, [messages]);

  return (
    <div className="space-y-6 max-w-chat mx-auto px-5 py-10">
      {shouldDisplayTodayDivider && (
        <div className="flex flex-row justify-center items-center">
          <div className="h-[1px] bg-content-secondary opacity-10 w-full" />
          <span className="absolute bg-background px-4 text-content-secondary text-xs">
            TODAY
          </span>
        </div>
      )}

      {taskId && (
        <PersonasIntroPanel
          taskId={taskId}
          date={messages[0]?.updatedAt.toDate()}
        />
      )}

      {messages?.map((message, index) => {
        const isLastMessage = index === messages.length - 1;
        return (
          <ChatMessage
            aiName={aiName}
            aiAvatarUrl={aiAvatarUrl}
            key={message.id}
            message={message}
            scrollToBottom={scrollToBottom}
            isReady={isReady}
            isLastMessage={isLastMessage}
            isTyping={isLastMessage && status === ChatStatus.STREAMING}
            onRegenerate={regenerateMessage}
            onRate={rateMessage}
          />
        );
      })}
      {[ChatStatus.REQUESTED, ChatStatus.REGENERATE].includes(status) && (
        <Loader className="pt-3" data-testid="chat-loading-indicator" />
      )}
    </div>
  );
}
