import { Paperclip } from "@phosphor-icons/react";
import React from "react";
import { Button, Tooltip, TooltipContent, TooltipTrigger } from "ui";
import { cn } from "~/lib/utils";
import { Text } from "~/components/Text";
import { UploadedFile } from "~/components/Chat/ChatInput/ChatInput";

interface Props {
  onUploadFile: () => void;
  fileType?: UploadedFile;
  showFileModal?: boolean;
}

const FileUploadButton = ({ onUploadFile, fileType, showFileModal }: Props) => {
  const isFileActive = fileType === UploadedFile.FILE && !showFileModal;
  const isDisabled = fileType === UploadedFile.IMAGE;

  return (
    <Tooltip>
      <TooltipTrigger className="cursor-pointer" asChild>
        <span>
          <Button
            size="icon"
            variant="ghost"
            type="button"
            className="text-icons-50 hover:text-primary relative"
            onClick={onUploadFile}
            data-testid="chat-input-file-button"
            disabled={isDisabled}
          >
            {isFileActive && (
              <div
                data-testid="file-attached"
                className={cn(
                  "h-3 w-3 absolute z-10 top-1 right-1 border-[3px] border-background  rounded-full bg-action-primary opacity-0 transition-opacity,",
                  isFileActive && "opacity-100",
                )}
              />
            )}
            <Paperclip className="h-6 w-6" />
          </Button>
          <TooltipContent data-testid="chat-input-file-button-tooltip">
            <Text
              id={
                isDisabled
                  ? "chats.tooltips.file-active"
                  : "chats.tooltips.file-upload"
              }
            />
          </TooltipContent>
        </span>
      </TooltipTrigger>
    </Tooltip>
  );
};

export default FileUploadButton;
