import { FileArrowDown, SpinnerGap, Check } from "@phosphor-icons/react";
import { Button } from "ui/src";
import React, { useState, useEffect } from "react";
import { httpsCallable } from "firebase/functions";
import { cn } from "~/lib/utils";
import { functions } from "~/integrations/firebase/functions";
import { useNotifications } from "~/context/NotificationsContext";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useUser } from "~/context/UserContext";

interface Props {
  chatId: string;
}

type ButtonState = "DEFAULT" | "GENERATING" | "SUCCESS";

const buttonIcon: Record<ButtonState, React.ReactNode> = {
  DEFAULT: (
    <FileArrowDown
      data-testid="default-icon"
      height="20px"
      width="20px"
      className="fill-content-secondary flex-shrink-0 opacity-1 group-hover:fill-content-primary"
    />
  ),
  GENERATING: (
    <SpinnerGap
      data-testid="generating-icon"
      height="20px"
      width="20px"
      className="animate-spin duration-500 fill-content-secondary flex-shrink-0 opacity-1 group-hover:fill-content-primary"
    />
  ),
  SUCCESS: (
    <Check
      data-testid="success-icon"
      height="20px"
      width="20px"
      fill="#05A551"
      className="flex-shrink-0 opacity-1"
    />
  ),
};

interface PdfUrlResponse {
  data: {
    url: string;
  };
}

const DownloadChatPdfButton = ({ chatId }: Props) => {
  const [buttonState, setButtonState] = useState<ButtonState>("DEFAULT");
  const { addNotification } = useNotifications();
  const { user } = useUser();
  const { log } = useAnalytics();

  const handleSaveToFile = (downloadUrl: string) => {
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "chat.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClick = async () => {
    setButtonState("GENERATING");

    try {
      const generateUrl = httpsCallable<{ chat_id: string }, PdfUrlResponse>(
        functions,
        "generate_chat_pdf_download_url",
      );
      const result: any = await generateUrl({ chat_id: chatId });
      const downloadUrl = result.data.url;

      if (downloadUrl) {
        handleSaveToFile(downloadUrl);
        setButtonState("SUCCESS");

        log({
          type: "chat_pdf_export",
          payload: {
            organisationId: user.organisationId,
            userId: user.userId,
          },
        });
      } else {
        throw new Error("No download URL provided");
      }
    } catch (error: any) {
      console.error("Error generating PDF download URL:", error);
      addNotification(
        "Failed to download PDF. Please try again.",
        "error",
        undefined,
        5000,
      );
      setButtonState("DEFAULT");
    }
  };

  useEffect(() => {
    if (buttonState === "SUCCESS") {
      const timer = setTimeout(() => {
        setButtonState("DEFAULT");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [buttonState]);

  return (
    <Button
      variant="outline"
      className={cn(
        "transition-all transform duration-300 group mr-4 overflow-hidden h-12 w-12 disabled:opacity-1",
        buttonState === "DEFAULT" && "hover:w-[91px]",
        buttonState === "GENERATING" && "w-[91px]",
        buttonState === "SUCCESS" && "w-12",
      )}
      onClick={handleClick}
      disabled={buttonState === "GENERATING" || buttonState === "SUCCESS"}
    >
      {buttonIcon[buttonState]}
      <div
        className={cn(
          "text-sm text-content-secondary translate-x-16 transition-all transform duration-300 w-0 group-hover:text-content-primary whitespace-nowrap",
          buttonState === "DEFAULT" &&
            "group-hover:translate-x-1 group-hover:w-[41px]",
          buttonState === "GENERATING" && "translate-x-1 w-[41px]",
          buttonState === "SUCCESS" && "hidden w-0",
        )}
      >
        Export
      </div>
    </Button>
  );
};

export default DownloadChatPdfButton;
