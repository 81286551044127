import React from "react";
import { Button, Label } from "ui";
import { UserPersonaTask } from "@noa/types";
import { useTasks } from "~/context/TasksContext";
import { cn } from "~/lib/utils";

interface Props {
  persona: UserPersonaTask;
  handleSlideOutPanel: (arg: UserPersonaTask) => void;
}

const PersonasCard = ({ persona, handleSlideOutPanel }: Props) => {
  const { getTaskAvatar } = useTasks();
  const personaUrl = getTaskAvatar(persona.id);
  const isDisabled = !persona.welcomeMessage;

  return (
    <div
      data-testid={persona.id}
      key={persona.id}
      className="group max-h-[300px] max-w-[247px] rounded-lg relative overflow-hidden "
    >
      <img
        className={cn(
          "object-cover w-full h-full",
          isDisabled && "filter grayscale opacity-70",
        )}
        src={personaUrl}
        alt={`${persona.name} smiling`}
      />

      <div className="absolute z-1 inset-0 bg-gradient-to-t from-black to-25% bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all" />
      <Label className="absolute z-3 top-2 right-2 bg-background px-3 py-2 rounded-md bg-black bg-opacity-30 text-white text-sm font-semibold ">
        {persona.generation}
      </Label>

      <div
        className={cn(
          "absolute left-0 bottom-0 w-full flex flex-col  pb-4 transition-transform duration-500 transform translate-y-[60px] group-hover:translate-y-0",
          isDisabled && "translate-y-[60px] group-hover:translate-y-[15px]",
        )}
      >
        <span className="text-md text-white font-bold px-4">
          {persona.name}
        </span>
        <span className="text-sm text-white px-4">{persona.age} years old</span>
        {!isDisabled ? (
          <Button
            variant="secondary"
            className="mt-4 mx-4"
            aria-label="View details about Emma Taylor"
            onClick={() => handleSlideOutPanel(persona)}
          >
            See details
          </Button>
        ) : (
          <div className="mt-4 h-[44px] w-full flex items-center justify-center text-xs alig bg-[#7B7B7B] text-white font-medium">
            Persona coming soon
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonasCard;
