import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { differenceInSeconds } from "date-fns";
import { throttle } from "lodash";
import { Chat, ChatStatus, Role } from "@noa/types/Chat";
import { useChat } from "~/context/ChatContext";
import { useInterval } from "~/hooks/useInterval";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useFiles } from "~/context/FilesContext";
import { ChatInput } from "~/components/Chat/ChatInput/ChatInput";
import { ChatMessages } from "~/components/Chat/ChatMessages";
import { useOrganisation } from "~/context/OrganisationContext";
import { ChatError } from "~/components/Chat/ChatError/ChatError";
import {
  CONTACT_URL,
  getErrorMessage,
} from "~/components/Chat/ChatError/getErrorMessage";
import NoaHeader from "~/components/NoaHeader/NoaHeader";

export default function ChatPage() {
  const {
    chat,
    attachFile,
    addMessage,
    messages,
    stopGeneration,
    onDelete,
    onRename,
  } = useChat();
  const messagesRef = useRef<HTMLDivElement>(null);
  const [showFileModal, setShowFileModal] = useState(false);
  const [imageInChat, setImageInChat] = useState(false);
  const { log } = useAnalytics();
  const { files } = useFiles();
  const { organisation } = useOrganisation();
  const shouldScrollToBottom = useRef(true);

  // Reset file modal when chat changes
  useEffect(() => {
    setShowFileModal(false);
  }, [chat.id]);

  // Get the ID of the last message
  const lastMessageId = messages[messages.length - 1]?.id;

  // Function to get seconds since last update
  const getSecondsSinceLastUpdate = (currentChat: Chat) => {
    if (currentChat.status === ChatStatus.READY || !currentChat.updatedAt)
      return 0;
    return differenceInSeconds(new Date(), currentChat.updatedAt.toDate());
  };

  // Handle scrolling behavior
  useEffect(() => {
    const currentMessageRef = messagesRef.current;
    if (!currentMessageRef) return;

    const handleScroll = throttle((event) => {
      const element = event.target as HTMLDivElement;
      shouldScrollToBottom.current =
        element.scrollTop >= element.scrollHeight - element.clientHeight - 100;
    }, 100);

    if (currentMessageRef?.scrollTo) {
      currentMessageRef.scrollTo({ top: currentMessageRef.scrollHeight });
      currentMessageRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      currentMessageRef.removeEventListener("scroll", handleScroll);
    };
  }, [chat.id]);

  const scrollToBottom = useCallback((force = false) => {
    if (!messagesRef.current?.scrollTo) return;
    if (!shouldScrollToBottom.current && !force) return;
    messagesRef.current.scrollTo({
      behavior: "smooth",
      top: messagesRef.current.scrollHeight,
    });
  }, []);

  // Update seconds since last update
  const [secondsSinceLastUpdate, setSecondsSinceLastUpdate] = useState(0);
  useInterval(
    () => setSecondsSinceLastUpdate(getSecondsSinceLastUpdate(chat)),
    1000,
  );

  // Determine the error message to display
  const errorMessage = useMemo(() => {
    if (!chat) return null;
    const { status, error, updatedAt } = chat;
    if (status === ChatStatus.ERROR) {
      return error
        ? getErrorMessage(error.errorCode)
        : `Apologies, Noa couldn't get a response. Please try again or [contact the Noa team](${CONTACT_URL}).`;
    }
    if (status === ChatStatus.READY || !updatedAt) return null;
    if (secondsSinceLastUpdate < 30) return null;
    return secondsSinceLastUpdate < 300
      ? "It looks like Noa is taking some time to respond."
      : "Apologies, Noa couldn't get a response.";
  }, [chat, secondsSinceLastUpdate]);

  // Log error messages
  useEffect(() => {
    if (errorMessage) {
      log({
        type: "chat_error_shown",
        payload: {
          chatId: chat.id,
          organisationId: chat.organisationId,
          errorMessage,
          latestMessageId: lastMessageId,
          organisationName: organisation.name,
        },
      });
    }
  }, [
    chat.id,
    chat.organisationId,
    errorMessage,
    lastMessageId,
    log,
    organisation.name,
  ]);

  const isLoading = [
    ChatStatus.REQUESTED,
    ChatStatus.REGENERATE,
    ChatStatus.STREAMING,
  ].includes(chat.status);

  // Prepare messages for display
  const displayMessages = useMemo(() => {
    const filteredMessages =
      messages?.filter((msg) => msg.role !== Role.SYSTEM) || [];
    if (chat.status === ChatStatus.REGENERATE) {
      filteredMessages.pop();
    }
    return filteredMessages;
  }, [messages, chat.status]);

  const isReady = chat.status === ChatStatus.READY;

  // Get the active file
  const activeFile = useMemo(() => {
    const fileIds = Object.keys(chat.files || {});
    return files.find((file) => file.id === fileIds[0]);
  }, [chat.files, files]);

  // Handle file upload actions
  const onUploadFile = useCallback(() => {
    log({
      type: "chat_file_upload_clicked",
      payload: {
        chatId: chat.id,
        organisationId: chat.organisationId,
        organisationName: organisation.name,
      },
    });
    setShowFileModal((prev) => !prev);
  }, [chat.id, chat.organisationId, log, organisation.name]);

  const onFileUploaded = useCallback(
    async (fileId: string) => {
      log({
        type: "chat_file_uploaded",
        payload: {
          chatId: chat.id,
          organisationId: chat.organisationId,
          fileId,
        },
      });
      await attachFile(fileId);
    },
    [attachFile, chat.id, chat.organisationId, log],
  );

  // Handle stopping message generation
  const onStopGeneration = useCallback(async () => {
    log({
      type: "chat_message_stop_generation",
      payload: { chatId: chat.id },
    });
    await stopGeneration();
  }, [chat.id, log, stopGeneration]);

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [displayMessages, scrollToBottom]);

  // Check if image is displayed in messages
  const isImageDisplayedInMessages = useMemo(
    () => messages.some((msg) => msg.files && msg.files.length > 0),
    [messages],
  );

  // Extract file IDs for message submission
  const extractFileIds = useCallback(() => {
    if (!imageInChat && activeFile && !isImageDisplayedInMessages) {
      setImageInChat(true);
      return [activeFile.id];
    }
    return [];
  }, [activeFile, imageInChat, isImageDisplayedInMessages]);

  // Submit a new message
  const submitMessage = useCallback(
    async (message: string) => {
      const fileIds = extractFileIds();
      await addMessage(message, lastMessageId, fileIds);
      scrollToBottom(true);
    },
    [addMessage, extractFileIds, lastMessageId, scrollToBottom],
  );

  const hasError = chat.status === ChatStatus.ERROR;

  return (
    <div
      className="flex-1 flex flex-col"
      data-page="chat"
      data-chat-status={chat.status}
    >
      <NoaHeader chat={chat} onRename={onRename} onDelete={onDelete} />

      <div className="overflow-auto flex-1" ref={messagesRef}>
        {chat && (
          <ChatMessages
            messages={displayMessages}
            scrollToBottom={scrollToBottom}
          />
        )}
      </div>

      <div className="px-5 pb-5">
        {!hasError ? (
          <ChatInput
            key={chat.id}
            showFileModal={showFileModal}
            activeFile={activeFile}
            disabled={!isReady}
            onSubmit={submitMessage}
            onUploadFile={onUploadFile}
            onUploadFileSuccess={onFileUploaded}
            onStopGeneration={onStopGeneration}
            showActions={!chat.taskId}
            isGenerating={isLoading}
          />
        ) : (
          <ChatError />
        )}
      </div>
    </div>
  );
}
