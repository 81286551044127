import { Avatar, AvatarFallback } from "ui";
import { AvatarImage } from "@radix-ui/react-avatar";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { Markdown } from "ui/src/markdown";
import { useTasks } from "~/context/TasksContext";
import { useTheme } from "~/context/ThemeProvider";
import { cn } from "~/lib/utils";
import TaskTipBoxTest from "~/components/Tasks/TaskTipBox/TaskTipBox";
import { useUser } from "~/context/UserContext";

interface Props {
  taskId: string;
  date?: Date;
}

const PersonasIntroPanel = ({ taskId, date }: Props) => {
  const { theme } = useTheme();
  const { getTaskById, getTaskAvatar } = useTasks();
  const { user } = useUser();
  const selectedPersona = useMemo(
    () => getTaskById(taskId),
    [getTaskById, taskId],
  );

  const personaUrl = getTaskAvatar(selectedPersona?.id);
  const showTipbox = !(user.tasks && user.tasks[taskId]?.tipbox.acknowledged);

  if (!selectedPersona) return null;

  return (
    <div
      className={cn("grid grid-cols-[2rem,1fr] gap-x-3 gap-y-1 ")}
      data-testid="persona-intro-panel"
    >
      <Avatar className="h-8 w-8 items-center">
        <AvatarImage
          data-testid="avatar"
          src={personaUrl}
          alt="Persona avatar"
        />
        <AvatarFallback>{selectedPersona.name[0]}</AvatarFallback>
      </Avatar>

      <div className="self-center space-x-1">
        <span className="font-semibold opacity-80">{selectedPersona.name}</span>
        <span className="font-normal opacity-60 text-sm">
          {format(date || new Date(), "H:mm aaaaa'm")}
        </span>
      </div>

      {selectedPersona.welcomeMessage && (
        <div className="col-start-2">
          <Markdown content={selectedPersona.welcomeMessage} theme={theme} />
        </div>
      )}

      {showTipbox && <TaskTipBoxTest taskId={taskId} />}
    </div>
  );
};

export default PersonasIntroPanel;
