import React from "react";

const PersonasDescription = () => {
  return (
    <div className="pt-24 flex w-full flex-col items-center">
      <h1 className="text-3xl text-content-secondary">User personas</h1>
      <p className=" max-w-[420px] pt-4 text-sm text-content-tertiary text-center">
        Dive into dialogue with our distinct personas below. <br />
        Each one is programmed to provide responses that perfectly fit your
        context and spice up your experience. <br />
      </p>
    </div>
  );
};

export default PersonasDescription;
