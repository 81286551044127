import {
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  Label,
  SidebarFoldGradient,
} from "ui";
import "./style.css";
import React, { useEffect, useRef, useState } from "react";
import { Plus, X as Cross } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { UserPersonaTask } from "@noa/types";
import { useTasks } from "~/context/TasksContext";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
  selectedPersona: UserPersonaTask;
}

const PersonasSlideOutPanel = ({
  isOpen,
  selectedPersona,
  setIsOpen,
}: Props) => {
  const { getTaskAvatar } = useTasks();
  const personaUrl = getTaskAvatar(selectedPersona.id);

  const [traitsPanel, setTraitsPanel] = useState<HTMLDivElement | null>(null);
  const traitsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (traitsRef && traitsRef.current) {
      setTraitsPanel(traitsRef.current);
    }
    // eslint-disable-next-line
  }, [traitsRef.current]);

  return (
    <Drawer
      noBodyStyles
      direction="right"
      open={isOpen}
      onOpenChange={(isDrawerOpen: boolean) => {
        setIsOpen(isDrawerOpen);
      }}
    >
      <DrawerContent
        ref={traitsRef}
        data-vaul-no-drag
        data-testid="persona-slideout-panel"
      >
        <DrawerHeader
          backgroundImage={
            <img
              className="absolute left-0 top-[-70px]  w-full z-[-1] opacity-10 pointer-events-none"
              src={personaUrl}
              alt={`${selectedPersona.name} smiling background`}
            />
          }
        >
          <DrawerTitle className="w-full flex justify-between items-center pb-4">
            <span>{selectedPersona.generation} Profile</span>
            <DrawerClose>
              <Cross data-testid="close-slideout" width="24" height="24" />
            </DrawerClose>
          </DrawerTitle>

          <section className="pt-2 bg-cover overflow-hidden h-auto">
            <div className="flex flex-row items-center w-full">
              <div className="rounded-full h-[72px] w-[72px] overflow-hidden border-2 border-white">
                <img
                  className="object-fill relative top-[-4px]"
                  src={personaUrl}
                  alt={`${selectedPersona.name} smiling`}
                />
              </div>
              <div className="pl-4 flex-1">
                <div className="font-semibold text-md text-content-primary">
                  {selectedPersona.name}
                </div>
                <div className="text-sm text-content-secondary">
                  {selectedPersona.age}
                </div>
              </div>
              <Label className="bg-background px-3 py-2 rounded-md bg-black bg-opacity-50 text-white text-sm font-semibold">
                {selectedPersona.generation}
              </Label>
            </div>
          </section>
        </DrawerHeader>

        <section className="relative z-1 rounded-lg overflow-auto">
          <div className="text-sm text-content-primary px-6 pb-6 z-1 sticky top-0">
            {selectedPersona.description}
          </div>

          <ul className="bg-background px-6 z-2 relative rounded-lg">
            {selectedPersona.traits.map((it) => {
              return (
                <li
                  key={it.title}
                  className="flex flex-row w-full justify-between items-center border-b pb-4 pt-4"
                >
                  <div className="flex flex-col">
                    <span className="text-content-tertiary font-semibold dark:text-content-primary">
                      {it.title}
                    </span>
                    <span className="text-md text-content-secondary">
                      {it.text}
                    </span>
                  </div>
                </li>
              );
            })}
          </ul>
        </section>
        <section className="bottom-0 sticky w-full px-6 pb-6 bg-background">
          <SidebarFoldGradient sidebarPanelRef={traitsPanel} />

          <Button className="h-[48px] w-full z-2 relative" asChild>
            <Link to={`/tasks/personas/${selectedPersona.id}/chat`}>
              <Plus className="mr-2 h-6 w-6 text-white opacity-50" />
              Start new chat
            </Link>
          </Button>
        </section>
      </DrawerContent>
    </Drawer>
  );
};

export default PersonasSlideOutPanel;
