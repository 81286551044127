import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from "react";
import {
  FeatureFlags,
  useLaunchDarkly,
} from "~/integrations/launch-darkly/LaunchDarklyContext";

export interface FeatureFlagUser {
  id: string;
  name?: string;
  email?: string;
  avatar?: string;
}

export interface FeatureFlagOrganisation {
  id: string;
  name?: string;
}

interface IFeatureFlagContext {
  features: FeatureFlags;
  setUser: (user: FeatureFlagUser | null) => void;
  setOrganisation: (organisation: FeatureFlagOrganisation | null) => void;
}

export const FeatureFlagContext = createContext<IFeatureFlagContext>(
  undefined as never,
);

export const useFeatureFlags = () => useContext(FeatureFlagContext);

export function FeatureFlagProvider({ children }: PropsWithChildren) {
  const { features, setContext, clearContext } = useLaunchDarkly();

  const setUser = useCallback(
    (user: FeatureFlagUser | null) => {
      if (!user) {
        clearContext();

        return;
      }

      setContext("user", {
        key: user.id,
        name: user.name,
        email: user.email,
        avatar: user.avatar,
      });
    },
    [setContext, clearContext],
  );

  const setOrganisation = useCallback(
    (organisation: FeatureFlagOrganisation | null) => {
      if (!organisation) {
        setContext("organisation", {
          anonymous: true,
        });

        return;
      }

      setContext("organisation", {
        key: organisation.id,
        name: organisation.name,
      });
    },
    [setContext],
  );

  const value = useMemo(
    () => ({ setOrganisation, setUser, features }),
    [setOrganisation, setUser, features],
  );

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
}
