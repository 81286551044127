import React, { useMemo, useRef, useState, useEffect, forwardRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { cn } from "./utils";
import {
  Avatar,
  AvatarFallback,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "./components";
import {
  DotsThree,
  MagnifyingGlass,
  Pencil,
  Plus,
  SidebarSimple,
  SmileySad,
  Trash,
  X,
} from "@phosphor-icons/react";
import { NoaLogo } from "./icons";
import { AvatarImage } from "@radix-ui/react-avatar";

export interface SidebarMainItem {
  id: string;
  link: string;
  title: string;
  icon?: React.ReactNode;
  taskUrl?: string;
  taskId?: string;
}

export interface SidebarItem extends SidebarMainItem {
  hideActions?: boolean;
}

export interface SidebarMainSection {
  title: string;
  items: SidebarMainItem[];
}

export interface SidebarSection extends SidebarMainSection {
  items: SidebarItem[];
}

export interface ChatSidebarProps {
  mainSections?: SidebarMainSection[];
  sections?: SidebarSection[];
  className?: string;
  isLoading?: boolean;

  onToggle(): void;

  onRename(id: string): void;

  onDelete(id: string): void;
}

interface SidebarItemActionsProps {
  onRename(): void;

  onDelete(): void;
}

const NOA_AVATAR_ICON_URL = "/noa.svg";

export function SidebarItemActions({
  onRename,
  onDelete,
}: SidebarItemActionsProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className="ml-auto shrink-0 group-[.active]:inline-flex hidden transition mr-1"
        data-testid="sidebar-item-actions"
      >
        <Tooltip>
          <TooltipTrigger asChild>
            <DotsThree className="h-8 w-8 text-icons mx-2" />
          </TooltipTrigger>

          <TooltipPortal>
            <TooltipContent>
              <span className="font-medium">VIEW ACTIONS</span>
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-48" side="bottom" align="end">
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={onRename}
            data-testid="sidebar-rename-chat-action"
          >
            <Pencil className="mr-3 h-5 w-5 text-icons-50" />
            <span className="text-content-primary">Rename chat</span>
          </DropdownMenuItem>

          <DropdownMenuItem
            onClick={onDelete}
            data-testid="sidebar-delete-chat-action"
          >
            <Trash className="mr-3 h-5 w-5 text-icons-50" />
            <span className="text-content-primary">Delete</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function SidebarItem({
  item,
  onRename,
  onDelete,
}: {
  item: SidebarItem;
  onRename(id: string): void;
  onDelete(id: string): void;
}) {
  const isTaskAvatar = !!item.taskUrl;
  const avatarUrl = isTaskAvatar ? item.taskUrl : NOA_AVATAR_ICON_URL;
  const avatarTestId = isTaskAvatar ? "task-avatar" : "noa-avatar";

  return (
    <NavLink
      to={item.link}
      className={(props) =>
        cn(
          "flex items-center rounded-lg group transition-all h-12 ml-3 relative overflow-hidden",
          props.isActive && "bg-background active shadow-sm z-low",
          !props.isActive && "text-content-secondary",
        )
      }
      data-testid="sidebar-item"
      data-id={item.id}
    >
      <div className="bg-purple-700 absolute left-0 top-0 bottom-0 shrink-0 transition-all h-full w-[3px] group-[.active]:opacity-100 opacity-0" />
      <Avatar data-testid={avatarTestId} className="ml-2 h-8 w-8 items-center">
        <AvatarImage src={avatarUrl} alt="chat avatar" />
        <AvatarFallback className="bg-gradient-to-r animate-skeleton bg-border from-[#E0E9F3] to-[rgba(255,255,255,.7)] animate-skeleton" />
      </Avatar>

      <div className="truncate py-2 px-3 opacity-90 group-[.active]:opacity-100">
        {item.title}
      </div>

      {!item.hideActions && (
        <SidebarItemActions
          onDelete={() => onDelete(item.id)}
          onRename={() => onRename(item.id)}
        />
      )}
    </NavLink>
  );
}

export function SidebarSection({
  section,
  onDelete,
  onRename,
}: {
  section: SidebarSection;
  onDelete(id: string): void;
  onRename(id: string): void;
}) {
  if (!section.items.length) {
    return null;
  }

  return (
    <div>
      <div className="sticky top-0 z-10 bg-secondary ml-3 px-3 text-sm uppercase font-semibold text-content-tertiary h-10 flex items-center">
        <span className="opacity-50">{section.title}</span>
      </div>

      {section.items.map((item) => (
        <SidebarItem
          key={item.id}
          item={item}
          onDelete={onDelete}
          onRename={onRename}
        />
      ))}
    </div>
  );
}

export function SidebarMainItem({ item }: { item: SidebarMainItem }) {
  return (
    <NavLink
      to={item.link}
      className={(props) =>
        cn(
          "flex items-center rounded-lg group transition-all h-12 pl-2 mx-3 relative overflow-hidden",
          props.isActive && "bg-background active shadow-sm z-low",
          !props.isActive && "text-content-secondary",
        )
      }
      data-testid="sidebar-main-item"
      data-id={item.id}
    >
      <div className="p-2 bg-white rounded-md">{item.icon}</div>
      <div className="bg-purple-700 absolute left-0 top-0 bottom-0 shrink-0 transition-all h-full w-[3px] group-[.active]:opacity-100 opacity-0" />
      <div className="truncate py-2 px-3 opacity-90 group-[.active]:opacity-100 ">
        {item.title}
      </div>
    </NavLink>
  );
}

export function SidebarMainSection({
  section,
}: {
  section: SidebarMainSection;
}) {
  if (!section.items.length) {
    return null;
  }

  return (
    <div>
      <div className="bg-secondary text-sm uppercase font-semibold text-content-tertiary h-10 ml-3 px-3 flex items-center">
        <span className="opacity-50">{section.title}</span>
      </div>

      {section.items.map((item) => (
        <SidebarMainItem key={item.id} item={item} />
      ))}
    </div>
  );
}

export const ChatSidebar = forwardRef<HTMLDivElement, ChatSidebarProps>(
  (
    {
      mainSections = [],
      sections = [],
      onToggle,
      className,
      onDelete,
      onRename,
      isLoading,
    },
    ref,
  ) => {
    const { register, watch, reset } = useForm<{ filter: string }>({});
    const sidebarPanelRef = useRef<HTMLDivElement>(null);

    const filter = watch("filter", "").trim();

    const filtered = useMemo(() => {
      if (!filter) {
        return sections;
      }

      const mapped = sections.map((section) => ({
        ...section,
        items: section.items.filter((item) =>
          item.title.toLowerCase().includes(filter.toLowerCase()),
        ),
      }));

      return mapped.filter((section) => section.items.length > 0);
    }, [filter, sections]);

    const [sidePanelEl, setSidePanelEl] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
      if (filtered.length > 0) {
        setSidePanelEl(sidebarPanelRef.current);
      }
    }, [filtered]);

    return (
      <div
        className={cn("overflow-hidden flex flex-col flex-1", className)}
        data-testid="sidebar"
        ref={ref}
      >
        <div className="p-6 space-y-5 flex flex-col">
          <div className="inline-flex items-center">
            <NavLink to="/">
              <NoaLogo className="h-6" />
            </NavLink>

            <Button
              size="icon"
              variant="ghost"
              className="ml-auto"
              onClick={onToggle}
              data-testid="sidebar-toggle"
            >
              <SidebarSimple className="h-8 w-8 text-icons-50" />
            </Button>
          </div>

          <label
            htmlFor="search"
            className="bg-background border border-input rounded-lg flex items-center px-4 py-3 dark:border-transparent focus:ring-2 focus:ring-red-500 transition-all"
          >
            <MagnifyingGlass className="mr-2 text-muted-foreground h-6 w-6" />

            <input
              id="search"
              placeholder="Search for a chat"
              className="flex-1 bg-transparent placeholder:text-sm placeholder:font-light placeholder:text-content-secondary outline-0"
              {...register("filter")}
            />

            {filter && (
              <Tooltip>
                <TooltipTrigger onClick={() => reset({ filter: "" })}>
                  <X className="h-4 w-4 text-icons-50 hover:text-icons" />
                </TooltipTrigger>

                <TooltipContent>
                  <span className="font-medium">CLEAR</span>
                </TooltipContent>
              </Tooltip>
            )}
          </label>
        </div>

        {isLoading && (
          <div className="flex-1 space-y-12 pb-6 px-6 overflow-hidden animate-pulse">
            {Array.from(Array(10)).map((_, index) => (
              <div key={index} className="space-y-6">
                <Skeleton className="h-6 w-1/3 mb-8" />
                <Skeleton className="h-4 w-4/5" />
                <Skeleton className="h-4 w-1/2" />
                <Skeleton className="h-4 w-3/5" />
                <Skeleton className="h-4 w-3/4" />
                <Skeleton className="h-4 w-5/6" />
                <Skeleton className="h-4 w-3/6" />
                <Skeleton className="h-4 w-3/5" />
              </div>
            ))}
          </div>
        )}

        {mainSections.map((mainSection) => (
          <SidebarMainSection section={mainSection} key={mainSection.title} />
        ))}

        {!isLoading && (
          <>
            {filtered.length === 0 && (
              <div className="flex-1 flex flex-col items-center py-2 space-y-4">
                <SmileySad className="text-icons-50 w-8 h-8" />

                <div className="text-center space-y-2">
                  <div className="text-content-secondary font-medium">
                    No results found
                  </div>
                  <div className="text-content-primary text-sm">
                    Try searching for another term
                  </div>
                </div>
              </div>
            )}

            {filtered.length > 0 && (
              <div
                className="flex-1 overflow-y-auto space-y-6 pb-5 mb-1 scrollbar-stable"
                data-testid="sidebar-sections"
                ref={sidebarPanelRef}
              >
                {filtered.map((section) => (
                  <SidebarSection
                    key={section.title}
                    section={section}
                    onDelete={onDelete}
                    onRename={onRename}
                  />
                ))}
              </div>
            )}
          </>
        )}

        <div className="flex px-6 pb-6 relative">
          <SidebarFoldGradient sidebarPanelRef={sidePanelEl} />

          <Button className="flex-1 z-low" asChild>
            <Link to="/">
              <Plus className="mr-2 h-6 w-6 text-white opacity-50" />
              Start new chat
            </Link>
          </Button>
        </div>
      </div>
    );
  },
);

interface SidebarFoldGradientProps {
  sidebarPanelRef: HTMLDivElement | null;
}

export function SidebarFoldGradient({
  sidebarPanelRef,
}: SidebarFoldGradientProps) {
  const [showGradientPanel, setShowGradientPanel] = useState(false);

  useEffect(() => {
    if (!sidebarPanelRef) return;

    const shouldDisplayGradientPanel = () => {
      const { clientHeight, scrollHeight, scrollTop } = sidebarPanelRef;
      const isOverflowing = scrollHeight > clientHeight;
      const marginFromBottom = 20;
      const isScrolledToBottom =
        scrollTop + clientHeight >= scrollHeight - marginFromBottom;

      if (isOverflowing) {
        setShowGradientPanel(isOverflowing);
      }

      if (isScrolledToBottom) {
        setShowGradientPanel(!isScrolledToBottom);
      }
    };

    shouldDisplayGradientPanel();
    window.addEventListener("resize", shouldDisplayGradientPanel);
    sidebarPanelRef.addEventListener("scroll", shouldDisplayGradientPanel);

    return () => {
      window.removeEventListener("resize", shouldDisplayGradientPanel);
      sidebarPanelRef.removeEventListener("scroll", shouldDisplayGradientPanel);
    };
  }, [sidebarPanelRef]);

  return (
    showGradientPanel && (
      <section
        data-testid="sidebar-fold-gradient"
        className="absolute sidebar-gradient w-full bottom-0 left-0 bg-gradient-to-t z-base from-secondary h-[216px] pointer-events-none"
      />
    )
  );
}

function Skeleton({ className = "" }) {
  return (
    <div
      className={cn(
        "h-4 bg-border rounded-md bg-gradient-to-r from-[rgba(255,255,255,0)] to-[rgba(255,255,255,0.3)]",
        className,
      )}
    />
  );
}
