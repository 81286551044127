import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { app } from "~/integrations/firebase/app";

const functions = getFunctions(app, "europe-west4");

if (import.meta.env.VITE_FIREBASE_EMULATOR_ENABLED) {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { functions };
