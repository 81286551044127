import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";
import { useAuth } from "~/context/AuthContext";
import { userIsSuperAdmin } from "~/utils/authO";
import { AdminOrganisationsProvider } from "~/context/AdminOrganisationsContext";

const AuthAdminLayout = () => {
  const { roles } = useAuth();
  const { pathname } = useLocation();

  if (!userIsSuperAdmin(roles)) {
    const to = ["/", "/logout"].includes(pathname)
      ? "/login"
      : `/login?redirect=${pathname}`;

    return <Navigate to={to} />;
  }

  return (
    <AdminOrganisationsProvider>
      <Outlet />
    </AdminOrganisationsProvider>
  );
};

export default AuthAdminLayout;
