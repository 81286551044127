import { ChatMessage as IChatMessage, RatingType } from "@noa/types";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { ChatMessage as Message } from "ui";
import { auth } from "~/integrations/firebase/auth";
import { useTheme } from "~/context/ThemeProvider";
import { ChatMessageActions } from "~/components/Chat/ChatMessage/ChatMessageActions";
import { copyMessage } from "~/utils/copyMessage";
import { ChatMessageFeedback } from "~/components/Chat/ChatMessages/ChatMessageFeedback";
import { ChatMessageFile } from "~/components/Chat/ChatMessages/ChatMessageFile";

export interface ChatMessageProps {
  message: IChatMessage;
  isLastMessage: boolean;
  isReady: boolean;
  isTyping: boolean;
  aiName?: string;
  aiAvatarUrl?: string;

  scrollToBottom(): void;

  onRate(id: string, rating: RatingType, message: string | null): Promise<void>;

  onRegenerate(): void;
}

export function ChatMessage({
  message,
  isLastMessage,
  isReady,
  isTyping,
  onRegenerate,
  onRate,
  scrollToBottom,
  aiName = "NOA",
  aiAvatarUrl,
}: ChatMessageProps) {
  const { theme } = useTheme();
  const [showFeedback, setShowFeedbackModal] = useState(false);
  const [authUser] = useAuthState(auth);

  const isAI = message.role === "AI";
  const senderName = isAI ? aiName : authUser?.displayName || "User";
  const avatarUrl = isAI ? aiAvatarUrl : authUser?.photoURL;

  const handleLike = async () => {
    await onRate(message.id, RatingType.LIKE, null);
  };

  const handleDislike = () => {
    setShowFeedbackModal(true);
  };

  const handleFeedbackSubmit = async (feedback: string) => {
    await onRate(message.id, RatingType.DISLIKE, feedback);
    setShowFeedbackModal(false);
  };

  const handleFeedbackClose = async () => {
    await onRate(message.id, RatingType.DISLIKE, null);
    setShowFeedbackModal(false);
  };

  useEffect(() => {
    if (isLastMessage) {
      scrollToBottom();
    }
  }, [isLastMessage, scrollToBottom, showFeedback]);

  return (
    <>
      <Message
        id={message.id}
        content={message.content || ""}
        theme={theme}
        sender={senderName}
        date={message.updatedAt?.toDate() || new Date()}
        avatar={avatarUrl}
        onType={scrollToBottom}
        isTyping={isTyping}
        actions={
          <ChatMessageActions
            message={message}
            isLastMessage={isLastMessage}
            isReady={isReady}
            onRegenerate={onRegenerate}
            onLike={handleLike}
            onDislike={handleDislike}
            onCopy={() => copyMessage(message.id, message.content || "")}
          />
        }
        files={
          message.files?.length ? (
            <ChatMessageFile fileId={message.files[0]} />
          ) : undefined
        }
      />

      {showFeedback && (
        <ChatMessageFeedback
          onSubmit={handleFeedbackSubmit}
          onClose={handleFeedbackClose}
        />
      )}
    </>
  );
}
