import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "ui";
import React from "react";
import { Link } from "react-router-dom";
import { Text } from "~/components/Text";
import { useUser } from "~/context/UserContext";

export function PersonasNotificationModal() {
  const { savePersonasModalChoice } = useUser();

  const onSelect = async (choice: boolean) => {
    await savePersonasModalChoice(choice);
  };

  return (
    <Dialog open>
      <DialogContent className="text-center" hideClose>
        <DialogHeader>
          <DialogTitle className="text-center">
            <Text
              id="notifications.personas.title"
              className="bg-noa-text bg-clip-text text-opacity-0 text-white font-medium text-2xl"
            />
          </DialogTitle>
        </DialogHeader>

        <p className="pb-6">
          <Text
            id="notifications.personas.subheader"
            className="text-content-secondary font-light text-sm prose dark:prose-invert "
          />
          <Text
            id="notifications.personas.message"
            className="text-content-secondary font-light text-sm prose dark:prose-invert "
          />
          <Text
            id="notifications.personas.footer"
            className="text-content-secondary font-light text-sm prose dark:prose-invert"
          />
        </p>
        <DialogFooter className="grid sm:grid-cols-2 gap-2">
          <Button variant="outline" onClick={() => onSelect(false)}>
            <Text id="notifications.personas.cancel" />
          </Button>
          <Link
            role="link"
            to="/tasks/personas"
            className="inline-flex flex-1"
            onClick={() => onSelect(true)}
          >
            <Button className="w-full">
              <Text id="notifications.personas.confirm" />
            </Button>
          </Link>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
