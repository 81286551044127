import { DownloadSimple as Download } from "@phosphor-icons/react";
import { Headset, HelpCircle, LogOut, Sun, Users } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "ui";
import { ThemePreference, useTheme } from "~/context/ThemeProvider";
import { useAuth } from "~/context/AuthContext";
import { useUser } from "~/context/UserContext";
import { userIsSuperAdmin } from "~/utils/authO";
import { useApplication } from "~/context/ApplicationContext";
import { Text } from "~/components/Text";

export function AccountMenu() {
  const { preference } = useTheme();
  const { setThemePreference } = useUser();
  const { authUser, roles } = useAuth();
  const { features } = useApplication();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild data-testid="menu-button">
        <Avatar className="h-12 w-12 ml-auto cursor-pointer shrink-0">
          <AvatarImage
            src={authUser?.photoURL ?? undefined}
            alt={authUser?.displayName ?? undefined}
          />
          <AvatarFallback>{authUser?.displayName?.[0]}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="w-56"
        side="bottom"
        align="end"
        data-testid="menu-content"
      >
        <DropdownMenuGroup>
          <DropdownMenuItem asChild data-testid="menu-item-help-center">
            <Link
              to="https://sites.google.com/chaptr.xyz/noa-help-center/home"
              target="_blank"
            >
              <HelpCircle className="mr-2 h-4 w-4" />
              <span>
                <Text id="account-menu.help-center" />
              </span>
            </Link>
          </DropdownMenuItem>

          <DropdownMenuItem asChild data-testid="menu-item-contact-us">
            <Link to="/support" target="_blank">
              <Headset className="mr-2 h-4 w-4" />
              <span>
                <Text id="account-menu.support" />
              </span>
            </Link>
          </DropdownMenuItem>

          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Sun className="mr-2 h-4 w-4" />
              <span>
                <Text id="account-menu.theme.title" />
              </span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuRadioGroup
                  value={preference}
                  onValueChange={(theme) =>
                    setThemePreference(theme as ThemePreference)
                  }
                >
                  <DropdownMenuRadioItem value="system">
                    <Text id="account-menu.theme.system" />
                  </DropdownMenuRadioItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuRadioItem value="light">
                    <Text id="account-menu.theme.light" />
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="dark">
                    <Text id="account-menu.theme.dark" />
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>

          {features.notifications.chromeExtension && (
            <DropdownMenuItem asChild data-testid="menu-item-admin-view">
              <Link
                to={import.meta.env.VITE_CHROME_EXTENSION_URL}
                target="_blank"
              >
                <Download className="mr-2 h-4 w-4" />
                <span>
                  <Text id="account-menu.chrome-extension" />
                </span>
              </Link>
            </DropdownMenuItem>
          )}

          {userIsSuperAdmin(roles) && (
            <DropdownMenuItem asChild data-testid="menu-item-admin-view">
              <Link to="/admin">
                <Users className="mr-2 h-4 w-4" />
                <span>
                  <Text id="account-menu.admin" />
                </span>
              </Link>
            </DropdownMenuItem>
          )}

          <DropdownMenuItem asChild data-testid="menu-item-logout">
            <Link to="/logout">
              <LogOut className="mr-2 h-4 w-4" />
              <span>
                <Text id="account-menu.log-out" />
              </span>
            </Link>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
