import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, DocumentReference, updateDoc } from "firebase/firestore";
import { UserProfile } from "@noa/types/UserProfile";
import { User } from "@noa/types/User";

import { db } from "~/integrations/firebase/firestore";
import { useProfile } from "~/hooks/useProfile";
import { ThemePreference, useTheme } from "~/context/ThemeProvider";
import { useAnalytics } from "~/context/AnalyticsProvider";

interface UserContextProps {
  user: User;
  profile?: UserProfile;

  updateUserProfile(profile: UserProfile): Promise<void>;

  setThemePreference(theme: ThemePreference): Promise<void>;

  saveChromeExtensionChoice(choice: boolean): Promise<void>;

  savePersonasModalChoice(choice: boolean): Promise<void>;

  saveTaskTipBoxChoice(taskId: string): Promise<void>;
}

export const UserContext = createContext<UserContextProps>(undefined as never);

export const useUser = () => useContext(UserContext);

interface Props {
  userId: string;
}

export const UserProvider = ({
  children,
  userId,
}: PropsWithChildren<Props>) => {
  const reference = useMemo(
    () => doc(db, "users", userId) as DocumentReference<User>,
    [userId],
  );
  const [user, loading] = useDocumentData<User>(reference);
  const { profile, updateUserProfile } = useProfile(userId);
  const { log } = useAnalytics();
  const { setTheme } = useTheme();

  useEffect(() => {
    if (user?.theme) {
      setTheme(user.theme as ThemePreference);
    }
  }, [setTheme, user?.theme]);

  const setThemePreference = useCallback(
    async (theme: ThemePreference) => {
      setTheme(theme);

      log({
        type: "theme_preference_set",
        payload: {
          theme,
        },
      });

      if (!user) {
        return;
      }

      await updateDoc(reference, { theme });
    },
    [log, reference, setTheme, user],
  );

  const saveChromeExtensionChoice = useCallback(
    async (redirected: boolean) => {
      log({
        type: "chrome_extension_popup_cta",
        payload: {
          redirected_to_google_store: redirected,
        },
      });

      if (!user) {
        return;
      }

      await updateDoc(reference, {
        "notifications.chromeExtension.acknowledged": true,
      });
    },
    [log, reference, user],
  );

  const savePersonasModalChoice = useCallback(
    async (redirected: boolean) => {
      log({
        type: "personas_popup_cta",
        payload: {
          redirected_to_personas_agent: redirected,
        },
      });

      if (!user) {
        return;
      }

      await updateDoc(reference, {
        "notifications.personas.acknowledged": true,
      });
    },
    [log, reference, user],
  );

  const saveTaskTipBoxChoice = useCallback(
    async (taskid: string) => {
      if (!user) {
        return;
      }

      const dbPath = `tasks.${taskid}.tipbox.acknowledged`;

      await updateDoc(reference, {
        [dbPath]: true,
      });
    },
    [reference, user],
  );

  const value = useMemo(() => {
    return {
      user: { ...user, userId } as UserContextProps["user"],
      profile,
      updateUserProfile,
      setThemePreference,
      saveChromeExtensionChoice,
      savePersonasModalChoice,
      saveTaskTipBoxChoice,
    };
  }, [
    user,
    userId,
    profile,
    updateUserProfile,
    setThemePreference,
    saveChromeExtensionChoice,
    savePersonasModalChoice,
    saveTaskTipBoxChoice,
  ]);

  if (loading) {
    return null;
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
