import { Navigate, Outlet, useLocation } from "react-router-dom";
import React from "react";
import { useAuth } from "~/context/AuthContext";
import { UserProvider } from "~/context/UserContext";
import { OrganisationProvider } from "~/context/OrganisationContext";
import { FilesProvider } from "~/context/FilesContext";
import { ChatsProvider } from "~/context/ChatsContext";
import { TasksProvider } from "~/context/TasksContext";
import { ThemeNotificationModal } from "~/components/Modal/ThemeNotificationModal";

const AuthLayout = () => {
  const { authUser, loading } = useAuth();
  const { pathname } = useLocation();

  if (loading) {
    return null; // TODO: Add loading screen
  }

  if (!authUser) {
    const to = ["/", "/logout"].includes(pathname)
      ? "/login"
      : `/login?redirect=${pathname}`;

    return <Navigate to={to} />;
  }

  return (
    <UserProvider userId={authUser.uid}>
      <OrganisationProvider>
        <FilesProvider>
          <ChatsProvider>
            <TasksProvider>
              <Outlet />

              <ThemeNotificationModal />
            </TasksProvider>
          </ChatsProvider>
        </FilesProvider>
      </OrganisationProvider>
    </UserProvider>
  );
};

export default AuthLayout;
