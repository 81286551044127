import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import IntroSection from "~/components/IntroSection";
import { useChats } from "~/context/ChatsContext";
import { useFiles } from "~/context/FilesContext";
import { useAnalytics } from "~/context/AnalyticsProvider";
import { useUser } from "~/context/UserContext";
import { useOrganisation } from "~/context/OrganisationContext";
import { ChatInput } from "~/components/Chat/ChatInput/ChatInput";
import NoaHeader from "~/components/NoaHeader/NoaHeader";

export default function ChatIntroPage() {
  const navigate = useNavigate();
  const { user } = useUser();
  const { createChatFromMessage } = useChats();
  const [showFileModal, setShowFileModal] = useState(false);
  const [fileId, setFileId] = useState<string>();
  const { files } = useFiles();
  const { log } = useAnalytics();
  const { organisation } = useOrganisation();
  const [params] = useSearchParams();

  const messageParams = useMemo(() => {
    return {
      message: params.get("message"),
    };
  }, [params]);

  const createChat = useCallback(
    async (message: string) => {
      const id = await createChatFromMessage(
        message,
        fileId ? [fileId] : undefined,
      );

      navigate(`/chat/${id}`);
    },
    [createChatFromMessage, fileId, navigate],
  );

  const onFileUploaded = useCallback(
    async (file: string) => {
      log({
        type: "chat_file_uploaded",
        payload: {
          fileId: file,
          organisationId: user.organisationId,
          organisationName: organisation.name,
        },
      });

      setFileId(file);
    },
    [log, organisation.name, user.organisationId],
  );

  const activeFile = useMemo(() => {
    if (!fileId) {
      return undefined;
    }

    return files.find((file) => file.id === fileId);
  }, [fileId, files]);

  const onUploadFile = () => {
    log({
      type: "new_chat_file_upload_clicked",
      payload: {
        organisationId: user.organisationId!,
        organisationName: organisation.name,
      },
    });

    setShowFileModal(!showFileModal);
  };

  const onRemoveFile = () => {
    log({
      type: "new_chat_remove_file_clicked",
      payload: {
        organisationId: user.organisationId!,
        organisationName: organisation.name,
      },
    });

    setFileId(undefined);
  };

  useEffect(() => {
    if (messageParams.message !== null) {
      createChat(messageParams.message).then();
    }
  }, [messageParams, createChat]);

  return (
    <div className="flex-1 flex flex-col">
      <NoaHeader />

      <div className="flex flex-1 overflow-y-auto items-center justify-center">
        <IntroSection />
      </div>

      <div className="px-5 pb-5">
        <ChatInput
          showFileModal={showFileModal}
          activeFile={activeFile}
          onSubmit={createChat}
          onUploadFile={onUploadFile}
          onUploadFileSuccess={onFileUploaded}
          onRemoveFile={onRemoveFile}
        />
      </div>
    </div>
  );
}
